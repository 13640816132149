import { Injectable } from '@angular/core';
import { AngularFirestore } from 'angularfire2/firestore';

@Injectable({
  providedIn: 'root'
})
export class CondominiumsService {
  private collection: string = 'condominiums';
  constructor(
    private afs: AngularFirestore
  ) { }
  filter(filter: any) {
    return new Promise((resolve) => {
      let valueChanges = this.afs.collection(this.collection, ref => {
        let query: firebase.firestore.CollectionReference | firebase.firestore.Query = ref;
        if (filter.type != null) {
          query = query.where('data.type', '==', filter.type);
        }
        if (filter.breed != null) {
          query = query.where('data.breed', '==', filter.breed);
        }
        if (filter.gender != null) {
          query = query.where('data.gender', '==', filter.gender);
        }
        if (filter.colors.length > 0) {
          query = query.where('data.colors', 'array-contains', filter.colors);
        }
        if (filter.eyesColors.length > 0) {
          query = query.where('data.eyesColors', 'array-contains', filter.eyesColors);
        }
        if (filter.fur != null) {
          query = query.where('data.fur', '==', filter.fur);
        }
        query = query.where('docRef', '>', '');
        return query;
      }).valueChanges();
      valueChanges.subscribe((result: any) => {
        resolve(result);
      });
    });
  }
  syncDocRef(docRef: any) {
    return this.afs.doc(this.collection + '/' + String(docRef).trim()).update({ docRef: docRef });
  }
  getPetsByDoc(docRef: any) {
    console.log(docRef)
    let collection = this.afs.doc(this.collection + '/' + String(docRef).trim()).collection('pets');
    return collection.valueChanges();
  }
  getByDoc(doc: any) {
    return this.afs.collection(this.collection).doc(doc).valueChanges();
  }
  getByUserDocRef(userRef) {
    return this.afs.collection(this.collection, user => user.where('user', '==', userRef)).valueChanges()
  }
  load() {
    return this.afs.collection(this.collection).valueChanges();
  }
  save(model: any) {
    return new Promise((resolve, reject) => {
      this.afs.collection(this.collection).add(model)
        .then((result: any) => {
          this.syncDocRef(result.id).then(() => {
            this.getByDoc(result.id).subscribe((commercialPoint: any) => {
              resolve(commercialPoint);
            });
          }).catch((error: any) => {
            reject(error);
          });
        }).catch((error: any) => {
          reject(error);
        });
    });
  }
}
